<template>
    <div>
        <info></info>
    </div>
</template>

<script>
import info from "../components/info";
export default {
    components:{
        info
    }
}
</script>

<style>
</style>